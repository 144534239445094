angular.module('common')
    .factory("_promise", function ($timeout, $q, $rootScope) {
        var pendingRequests = {};
        var functionStub = function () {
        };

        (function () {
            var requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame ||
                window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;
            window.requestAnimationFrame = requestAnimationFrame;
        })();

        var wrap = function () {
            var deferred = $q.defer();
            deferred.resolve.apply(this, arguments);
            return deferred.promise;
        };

        var stub = wrap();
        var stubFail = (function () {
            var deferred = $q.defer();
            deferred.reject();
            return deferred.promise;
        })();

        var EMPTY_STUB = function () {
            return wrap();
        };

        var sleep = function (timeout) {
            var deferred = $q.defer();

            setTimeout(function () {
                deferred.resolve();
            }, timeout || 0);

            return deferred.promise;
        };

        var sleepFunc = function (timeout) {
            return function () {
                return sleep(timeout);
            };
        };

        var tick = function (callback) {
            return $timeout(callback || functionStub);
            //window.requestAnimationFrame(function() {
            //    window.requestAnimationFrame(function() {
            //        $rootScope.$safeApply(callback);
            //    })
            //})
        };

        var chain = function (delay) {
            var chain = wrap();

            var _this = {
                then: function (promise) {
                    chain = chain.then(promise);

                    if (!_.isUndefined(delay)) {
                        chain = chain.then(sleepFunc(delay));
                    }

                    return _this;
                }
            };

            return _this;
        };

        var withDurationAtLeast = function (duration, promise) {
            if (promise) {
                return $q.all([promise, sleep(duration)]).then(function (answer) {
                    return answer[0]
                });
            } else {
                return sleep(duration);
            }
        };

        //https://github.com/500tech/angular-resolve-last/blob/master/dist/resolve_last.js
        function last(name, promise) {
            pendingRequests[name] || (pendingRequests[name] = []);

            var deferred = $q.defer();

            (function () {
                var requestId = Math.random();

                pendingRequests[name].push(requestId);

                if (_.isFunction(promise)) {
                    promise = promise();
                }
                if (!promise.then) {
                    console.error("Must provide a promise or a function that returns a promise");
                }
                return promise.then(function () {
                    if (_.last(pendingRequests[name]) === requestId) {
                        deferred.resolve.apply(deferred, arguments);
                    }
                    return _.remove(pendingRequests[name], function (id) {
                        return id === requestId;
                    });
                });
            })();

            return deferred.promise;
        }

        return {
            wrap: wrap,
            stub: stub,
            stubFail: stubFail,
            sleep: sleep,
            sleepFunc: sleepFunc,
            tick: tick,
            chain: chain,
            last: last,
            withDurationAtLeast: withDurationAtLeast,
            EMPTY_STUB: EMPTY_STUB
        }
    });